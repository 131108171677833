import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18c51fb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slots" }
const _hoisted_2 = {
  key: 0,
  class: "slots__wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "slots__wrapper slots__wrapper--loading"
}
const _hoisted_4 = { class: "slots__slot slots__slot--empty" }
const _hoisted_5 = {
  key: 2,
  class: "slots__wrapper slots__wrapper--empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading && _ctx.data.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (slot, index) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 6,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(`slots__slot ${
              slot.class ? 'slots__slot--' + slot.class : ''
            }`)
                    }, [
                      _createVNode(_component_a_button, {
                        type: _ctx.value === index ? 'secondary' : 'default',
                        onClick: ($event: any) => (_ctx.setCurrentSlot(index)),
                        disabled: slot.disabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(("0" + slot.from.getHours()).substr(-2)) + ":" + _toDisplayString(("0" + slot.from.getMinutes()).substr(-2)) + " - " + _toDisplayString(("0" + slot.to.getHours()).substr(-2)) + ":" + _toDisplayString(("0" + slot.to.getMinutes()).substr(-2)), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type", "onClick", "disabled"])
                    ], 2 /* CLASS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(new Array(16).fill(0), (emptySlot, index) => {
                  return (_openBlock(), _createBlock(_component_a_col, {
                    xs: 24,
                    sm: 12,
                    md: 8,
                    lg: 6,
                    key: index
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_button, {
                          loading: true,
                          disabled: true
                        })
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_a_empty, {
              description: _ctx.t('NO_DATA')
            }, null, 8 /* PROPS */, ["description"])
          ]))
  ]))
}
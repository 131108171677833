
import { defineComponent, PropType } from "vue";
import { SlotModel } from "../../types";
import { useI18n } from "vue-i18n";

export const SlotsComponent = defineComponent({
  props: {
    data: {
      type: Array as PropType<Array<SlotModel>>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const setCurrentSlot = (slotIndex: number) => {
      emit("update:value", slotIndex);
    };

    return {
      setCurrentSlot,
      t,
    };
  },
});
export default SlotsComponent;
